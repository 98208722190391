const ecoTabs = document.querySelectorAll('.e-tab')

const clearAll = () => {
  ecoTabs.forEach(item => item.classList.remove('active'))
}
ecoTabs.forEach(item => {

  item.addEventListener('mouseenter', e => {
    clearAll()
    item.classList.add('active')
  })


})
