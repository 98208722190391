const newsTabs = document.querySelectorAll('.news-tab')

const firstContent =  newsTabs[0].querySelector('.news-tab__content')
const firstHeader =  newsTabs[0].querySelector('.news-tab__header')

firstHeader.style.backgroundColor = "#307fe2"
firstHeader.style.color = "#fff"
firstContent.style.maxHeight  = firstContent.scrollHeight + 'px'
const clearAll  =() => {
  newsTabs.forEach(item => {
    const content = item.querySelector('.news-tab__content')
    const contentHeader = item.querySelector('.news-tab__header')
    contentHeader.style.backgroundColor = null
    contentHeader.style.color = null
    content.style.maxHeight  = null
  })
}

newsTabs.forEach(item => {
  const content = item.querySelector('.news-tab__content')
  const contentHeader = item.querySelector('.news-tab__header')
  item.addEventListener('mouseenter', e => {
    clearAll()
    contentHeader.style.backgroundColor = "#307fe2"
    contentHeader.style.color = "#fff"
    content.style.maxHeight  = content.scrollHeight + 'px'
  })
})
