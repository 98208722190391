
const menuOpen = document.querySelector('.menu__capture')
const menuClose = document.querySelector('.menu__close div')

const menu = document.querySelector('.menu-content')

menuOpen.addEventListener('click', e => {
  menu.classList.add('active')
})
menuClose.addEventListener('click', e => {
  menu.classList.remove('active')
})


const menuSolutionsBtn = document.querySelector('.menu-sol__header')
const menuSolutionsContent = document.querySelector('.menu-sol__content')

menuSolutionsBtn.addEventListener('click', e => {
  let isActive = menuSolutionsBtn.classList.toggle('active')

  menuSolutionsContent.style.maxHeight = isActive ? '100vh' : null
})
